import gql from 'graphql-tag';

export const SHOW_TASK = gql `
  query task($id: String!) {
    task(id: $id) {
      id
      title
      due_date
      due_time
      loggable_name
      description
      has_read
      formatted_total_time_logged
      comments_count
      total_time_logged
      presentable_due_time
      presentable_due_date
      virtual_due_date
      has_read
      is_completed
      restrict_editable
      is_newly_created
      uuid
      is_cancelled
      show_path
      is_archived
      due_today
      virtual_due_time
      formatted_created_at
      lock_version
      class_name
      formatted_updated_at
      category_name
      cancelled_at
      updated_at
      task_url
      task_follower_ids
      attachment_ids
      creator_name
      last_netsuite_sync_at
      netsuite_id
      netsuite_link
      listed_taskable {
        id
        taskable_name
        entity_url
        is_modal_previewable
      }
      comment_thread {
        id
      }
      task_list {
        id
        taskable_id
        taskable_type
      }
      followers {
        id
      }
      creator {
        id
        name
        actual_name
        profile_picture_url
        show_url
      }
      assignee {
        id
        name
        actual_name
        profile_picture_url
        show_url
      }
      task_category {
        id
        name
      }
      attachables_attachments_attributes {
        _destroy
        id
        attachment_id
        attachment_attributes {
          id
          name
          file_icon
          thumbnail_url
          document_url
          document_file_name
          file_icon_name
          errors {
            field_name
            errors
          }
        }
      }
      attachments {
        id
        name
        file_icon
        document_file_name
        document_url
        size_in_kb
        thumbnail_url
      }
    }
  }
`;
