import gql from 'graphql-tag';
import COMMENT_FIELDS from 'queries/comments/fetch_comments_fields'

export const FETCH_CONTACT = gql `
  query fetch_contact($id: String!) {
    fetch_contact(id: $id) {
      id
      name
      uuid
      email
      mobile_number
      phone_with_prefix
      mobile_number_with_prefix
      title
      section
      loggable_name
      show_url
      edit_url
      type
      lead_status
      created_at
      first_touch_by_id
      creator_id
      updated_at
      last_activity_on
      last_netsuite_sync_at
      netsuite_id
      netsuite_link
      department {
        id
        name
      }
      referral_source_details
      account {
        id
        show_url
        favicon_image
        name
        is_credit_limit_exceeded
        credit_left
        credit_information {
          credit_limit
        }
      }
      shipping_address {
        full_address
      }
      billing_address {
        full_address
      }
      account_team {
        id
        name
        members_with_lead {
          abbreviated_name
          profile_picture_url
        }
      }
      ${COMMENT_FIELDS}
    }
  }
`;
